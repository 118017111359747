import React, { useState, useEffect } from 'react';
import { getOnlineStudentData } from '../../Api'; // Import the API function
import './Confirm.css';
import Form from 'react-bootstrap/Form';

function Confirm() {
    const [studentData, setStudentData] = useState({});

    useEffect(() => {
        const fetchStudentData = async () => {
            const studentID = localStorage.getItem('studentID');
            if (studentID) {
                try {
                    const data = await getOnlineStudentData(studentID);
                    setStudentData(data);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchStudentData();
    }, []);

    return (
        <div>
            <section className='confirm'>
                <div className='confirm_form'>
                    <div className='confirm_img_form'>
                        <img src="/assets/logo.jpg" alt="" />
                    </div>
                    <div className='confirm_text_form'>
                        <h1>Confirm Your Details</h1> <br />
                        <Form.Label htmlFor="inputPassword5">Membership ID</Form.Label>
                        <input
                            className='input'
                            type="text"
                            name="Membership_ID"
                            id="Membership_ID"
                            value={studentData.code_stundent || ''}
                            placeholder="Membership ID"
                            required=""
                            autoComplete="off"
                            readOnly
                        /> <br />

                        <Form.Label htmlFor="inputPassword5">Student Level</Form.Label>
                        <input
                            className='input'
                            type="text"
                            name="Student_Level"
                            id="Student_Level"
                            value={studentData.description_course || ''}
                            placeholder="Student Level"
                            required=""
                            autoComplete="off"
                            readOnly
                        /> <br />

                        <Form.Label htmlFor="inputPassword5">Member</Form.Label>
                        <input
                            className='input'
                            type="text"
                            name="Member_Mame"
                            id="Member_Mame"
                            value={studentData.fullname_student || ''}
                            placeholder="Member Name"
                            required=""
                            autoComplete="off"
                            readOnly
                        /> <br />

                        <Form.Label htmlFor="inputPassword5">Birthday</Form.Label>
                        <input
                            className='input'
                            type="date"
                            name="Birth_Day"
                            id="Birth_Day"
                            value={studentData.dob_student ? new Date(studentData.dob_student).toISOString().split('T')[0] : ''}
                            placeholder="Birth Day"
                            required=""
                            autoComplete="off"
                            readOnly
                        /> <br />

                        <Form.Label htmlFor="inputPassword5">Mobile</Form.Label>
                        <input
                            className='input'
                            type="text"
                            name="Mobile_Number"
                            id="Mobile_Number"
                            value={studentData.mobile_student || ''}
                            placeholder="Mobile Number"
                            required=""
                            autoComplete="off"
                            readOnly
                        /> <br />

                        <a href="/payment">
                            <button className='button' variant="primary">Confirm</button>
                        </a>
                        <div className='privacy'>
                            <p>
                                <a href="privacy" target='_blank'>Privacy Policy</a> | {""}
                                <a href="terms" target='_blank'>Terms & Conditions</a> | {""}
                                <a href="" target='_blank'>Return Policy</a>
                            </p>
                            <p>**Please Contact Us on 0112 680 882 for support</p>
                        </div>
                    </div>
                </div>
                <p className='text-center'>Powered By <a href="https://ceylonxcorp.com/">CeylonX Corporation</a></p>
            </section>
        </div>
    );
}

export default Confirm;
