// Home.js
import React, { useState } from "react";
import "./Home.css";
import Form from "react-bootstrap/Form";
import { loginUser } from "../../Api"; // Import the API function

function Home() {
  const [membershipID, setMembershipID] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await loginUser(membershipID, birthDay);
      if (data.success) {
        localStorage.setItem('token', data.token); // Save the token in local storage
        localStorage.setItem('studentID', membershipID); // Save the student ID in local storage
        localStorage.setItem('expiresAt', Date.now() + 10 * 60 * 1000); // Set expiration time for 10 minutes
        window.location.href = "/confirm";
      } else {
        setError("Invalid membership ID or birth day");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <section className="home">
        <div className="home_form">
          <div className="img_form">
            <img src="/assets/logo.jpg" alt="" />
          </div>
          <div className="form">
            <h1>Pay Your Membership Online</h1> <br />
            <Form.Label htmlFor="inputPassword5">Membership ID</Form.Label>
            <input
              className="input"
              type="text"
              name="Membership_ID"
              id="Membership_ID"
              placeholder="Membership ID"
              required=""
              autoComplete="off"
              value={membershipID}
              onChange={(e) => setMembershipID(e.target.value)}
            />
            <br />
            <Form.Label htmlFor="inputPassword5">Birthday</Form.Label>
            <input
              className="input"
              type="date"
              name="Birth_Day"
              id="Birth_Day"
              placeholder="Birth Day"
              required=""
              autoComplete="off"
              value={birthDay}
              onChange={(e) => setBirthDay(e.target.value)}
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
            <button className="home_button" variant="primary" onClick={handleSubmit}>
              Next
            </button>
            <p>
              <a href="privacy" target="_blank">
                Privacy Policy
              </a>{" "}
              | {""}
              <a href="terms" target="_blank">
                Terms Conditions
              </a>{" "}
              | {""}
              <a href="" target="_blank">
                Return Policy
              </a>
            </p>
          </div>
        </div>
        <p className="text-center">
          Powered By <a href="https://ceylonxcorp.com/">CeylonX Corporation</a>
        </p>
      </section>
    </div>
  );
}

export default Home;
