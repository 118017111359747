import React, { useState, useEffect } from 'react';
import './Back.css';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { checkPaymentStatus } from '../../Api';


function Back() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('orderID');
    const studentId = queryParams.get('studentID');

    const [paymentStatus, setPaymentStatus] = useState(null);

    useEffect(() => {
        async function fetchPaymentStatus() {
            try {
                const response = await checkPaymentStatus(orderId, studentId);
                if (response.success) {
                    setPaymentStatus('success');
                } else {
                    setPaymentStatus('failed');
                }
            } catch (err) {
                console.log("Error occurred while checking payment details");
                setPaymentStatus('failed'); // Optionally handle the error case
            }
        }

        fetchPaymentStatus();
    }, [orderId, studentId]);

    const handleGetReceipt = () => {
        window.location.href = `/receipt?orderID=${orderId}&studentID=${studentId}`;
    }

    const handleTryAgain = () => {
        window.location.href = '/';
    }

    return (
        <div>
            <section className='back'>
                <div className='back_form'>
                    <div className='back_img_form'>
                        <img src="/assets/logo.jpg" alt="" />
                    </div>
                    <div className='back_text_form'>
                        {paymentStatus === 'success' ? (
                            <div className='form'>
                                <h1>Congratulations!</h1>
                                <h3>Payment successful</h3>

                                <button className="button_back" variant="primary" onClick={handleGetReceipt}>
                                    Get Receipt
                                </button>
                                <p>Click the button to get the receipt</p>
                            </div>
                        ) : paymentStatus === 'failed' ? (
                            <div className='form'>
                                <h3>Payment unsuccessful!</h3>
                                <button className="button_back" variant="primary" onClick={handleTryAgain}>
                                    Try again
                                </button>
                                <p>Please enter the correct details and try again</p>
                            </div>
                        ) : (
                            <p>Please wait...</p>
                        )}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Back